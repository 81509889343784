import { gql } from '@apollo/client';
import { TitleVariant } from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { FieldFragment, HeadingFieldFragment } from '../../generated/graphql';
import React, { FC } from 'react';
import { Box } from '@rebass/grid';
import { TitleWrapper } from '../../constants/tagMap';

export const fragment = gql`
    fragment HeadingField on Field_Heading {
        headingSize
    }
`;

const getVariantFromHeading = (heading: string): TitleVariant => {
    switch (heading) {
        case 'h2':
            return 'large';
        case 'h3':
            return 'regular';
        case 'h4':
            return 'small';
        case 'h5':
            return 'tiny';
        case 'h6':
            return 'micro';
        default:
            return 'regular';
    }
};

interface Props {
    field: FieldFragment & HeadingFieldFragment;
}

const HeadingField: FC<React.PropsWithChildren<Props>> = ({ field }) => (
    <Box>
        <TitleWrapper variant={getVariantFromHeading(field.headingSize ?? '')}>{field.label}</TitleWrapper>
    </Box>
);

export default HeadingField;

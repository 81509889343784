import { gql } from '@apollo/client';
import { FieldFragment, FieldRendererFragment, GroupFieldFragment } from '../generated/graphql';
import TextField from './fields/TextField';
import DropdownField from './fields/DropdownField';
import NumberField from './fields/NumberField';
import CheckboxesField from './fields/CheckboxesField';
import RadioField from './fields/RadioField';
import DateField from './fields/DateField';
import FileField from './fields/FileField';
import React, { ComponentProps, FC, Fragment, ReactNode } from 'react';
import GroupField from './fields/GroupField';
import styled from 'styled-components';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Box } from '@rebass/grid';
import Fieldset from '@oberoninternal/travelbase-website/dist/components/designsystem/Fieldset';
import HeadingField from './fields/HeadingField';
import AddressField from './fields/AddressField';
import AgreeField from './fields/AgreeField';
import NameField from './fields/NameField';

export const fragment = gql`
    fragment FieldRenderer on FieldInterface {
        ...Field

        # all specific fields go here
        ...TextField
        ...NumberField
        ...CheckboxesField
        ...RadioField
        ...DropdownField
        ...DateField
        ...FileField
        ...HeadingField
        ...AddressField
        ...AgreeField
        ...NameField
    }

    fragment Field on FieldInterface {
        instructions
        instructionsPosition
        label
        placeholder
        required
        handle
        errorMessage
    }
`;

type Field = FieldRendererFragment | GroupFieldFragment;
interface Props {
    fields: Field[];
}

const renderField = (field: Field): ReactNode => {
    switch (field.__typename) {
        case 'Field_SingleLineText':
        case 'Field_MultiLineText':
        case 'Field_Email':
        case 'Field_Phone':
            return <TextField field={field} />;
        case 'Field_Dropdown':
            return <DropdownField field={field} />;
        case 'Field_Number':
            return <NumberField field={field} />;
        case 'Field_Checkboxes':
            return <CheckboxesField field={field as ComponentProps<typeof CheckboxesField>['field']} />;
        case 'Field_Radio':
            return <RadioField field={field as ComponentProps<typeof RadioField>['field']} />;
        case 'Field_Date':
            return <DateField field={field} />;
        case 'Field_Group':
            return <GroupField field={field as ComponentProps<typeof GroupField>['field']} />;
        case 'Field_Heading':
            return <HeadingField field={field} />;
        case 'Field_FileUpload':
            return <FileField field={field} />;
        case 'Field_Address':
            return <AddressField field={field} />;
        case 'Field_Name':
            return <NameField field={field} />;
        case 'Field_Agree':
        case 'Field_Newsletter':
            return <AgreeField field={field} />;
        default:
            // eslint-disable-next-line no-console
            console.warn(`FieldRenderer: unknown field type ${field?.__typename}`);
            return null;
    }
};

const BELOW_INPUT = 'BelowInput';
const WITHOUT_FIELDSET: Array<FieldFragment['__typename']> = ['Field_Heading', 'Field_Address'];

const FieldRenderer: FC<React.PropsWithChildren<Props>> = ({ fields }) => (
    <>
        {fields.map((field: FieldFragment, i) => {
            const shouldRenderFieldset = !WITHOUT_FIELDSET.includes(field.__typename);
            const fieldElement = (
                <>
                    {field.instructions && !field.instructionsPosition?.endsWith(BELOW_INPUT) && (
                        <Box mb={3} mt={-2}>
                            <Instructions>{field.instructions}</Instructions>
                        </Box>
                    )}
                    {renderField(field)}
                </>
            );

            return (
                <Fragment key={i}>
                    {shouldRenderFieldset ? (
                        <Fieldset
                            label={field.required ? field.label?.concat(' *') : field.label}
                            id={field.handle ?? ''}
                        >
                            {fieldElement}
                        </Fieldset>
                    ) : (
                        fieldElement
                    )}

                    {field.instructions && field.instructionsPosition?.endsWith(BELOW_INPUT) && (
                        <Box mb={4}>
                            <Instructions>{field.instructions}</Instructions>
                        </Box>
                    )}
                </Fragment>
            );
        })}
    </>
);

const Instructions = styled(Body).attrs({ variant: 'tiny' })`
    color: ${({ theme }) => theme.colors.neutral[50]};
`;

export default FieldRenderer;

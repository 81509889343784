import { gql } from '@apollo/client';
import styled from 'styled-components';
import { FieldRendererFragment, FormFragment } from '../generated/graphql';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Form as FormikForm, Formik } from 'formik';
import SmallContentWrapper from './SmallContentWrapper';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import { Box } from '@rebass/grid';
import HtmlMapper from 'react-html-map';
import tagMap from '../constants/tagMap';
import FieldRenderer from './FieldRenderer';
import { buildInitialValues, CsrfResponse, subscribeByForm, toFormData } from '../utils/formUtils';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import theme from '../constants/theme';

export const fragment = gql`
    fragment Form on FormInterface {
        id
        handle
        siteId
        settings {
            submitActionFormHide
            submitActionMessageHtml
            submitAction
            submitActionTab
            redirectUrl
            integrations {
                handle
                enabled
            }
        }
        formFields {
            ...FieldRenderer
            ...GroupField
        }
    }
`;

interface Props {
    form: FormFragment;
}

export const globalValidationMessages = defineMessages({
    required: {
        defaultMessage: 'Dit veld is verplicht',
    },
});

const SUBMIT_URL = `/api/cms/formie/submissions/submit/`;

const Form: FC<React.PropsWithChildren<Props>> = ({ form }) => {
    const initialValues = useMemo(() => buildInitialValues(form.formFields), [form]);
    const [error, setError] = useState<Error | null>(null);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const { locale } = useIntl();
    const myRef = useRef<null | HTMLFormElement>(null);
    const executeScroll = () => myRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

    const shouldHide = submitted && form.settings?.submitActionFormHide;
    const { executeRecaptcha } = useGoogleReCaptcha();
    const hasRecaptchaEnabled = form.settings?.integrations?.some(
        integration => integration?.handle === 'recaptcha' && integration?.enabled === true
    );

    return (
        <SmallContentWrapper>
            <Formik
                onSubmit={async values => {
                    let submitActionWindow: Window | null = null;
                    try {
                        if (form.settings?.submitActionTab !== 'same-tab' && form.settings?.submitAction === 'url') {
                            // open window before doing any async stuff (https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari)
                            submitActionWindow = window.open('about:blank', '_blank');
                        }

                        const tokenResponse = await fetch(`/api/cms/formie/forms/refresh-tokens?form=${form.handle}`);
                        const tokenData = await tokenResponse.json();

                        const csrfToken = {
                            name: tokenData.csrf.param,
                            value: tokenData.csrf.token,
                        };

                        const recaptchaToken = hasRecaptchaEnabled ? await executeRecaptcha?.() : '';
                        const formData = toFormData(
                            values,
                            form.formFields,
                            recaptchaToken,
                            form.handle,
                            form.siteId,
                            csrfToken as CsrfResponse
                        );

                        subscribeByForm(values, form.formFields, locale);

                        await fetch(SUBMIT_URL, {
                            method: 'POST',
                            body: formData,
                            credentials: 'include',
                            redirect: 'manual',
                        }).then(response =>
                            // if the backend attempted to redirect us, assume all is well.
                            response.type === 'opaqueredirect'
                                ? Promise.resolve()
                                : Promise.reject(new Error(`Server Error ${response.status} ${response.statusText}`))
                        );

                        switch (form.settings?.submitAction) {
                            case 'url':
                                if (form.settings.submitActionTab === 'same-tab') {
                                    window.location.assign(form.settings?.redirectUrl ?? '/');
                                } else {
                                    submitActionWindow!.location.href = form.settings?.redirectUrl ?? '/';
                                }
                                break;
                            case 'reload':
                                window.location.reload();
                                break;
                            default:
                            case 'message':
                                setSubmitted(true);
                                executeScroll();
                                break;
                        }
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } catch (ex: any) {
                        submitActionWindow?.close();
                        setError(ex);
                    }
                }}
                initialValues={initialValues}
            >
                {({ isSubmitting }) => (
                    <FormikForm id="formElement" ref={myRef}>
                        {error && <ErrorMessage error={error} />}

                        {submitted && form.settings?.submitAction === 'message' && (
                            <SubmissionMessageContainer>
                                <HtmlMapper html={form.settings.submitActionMessageHtml ?? ''}>{tagMap}</HtmlMapper>
                            </SubmissionMessageContainer>
                        )}
                        {!shouldHide && (
                            <>
                                <FieldRenderer fields={(form.formFields as FieldRendererFragment[]) ?? []} />
                                <Button type="submit" disabled={isSubmitting}>
                                    <FormattedMessage defaultMessage="Verzenden" />
                                </Button>
                                {hasRecaptchaEnabled && (
                                    <Box mt={3}>
                                        <Body variant="micro">
                                            <FormattedMessage
                                                defaultMessage="Deze site wordt beschermd door reCAPTCHA en het Google {privacy} en {terms} zijn van toepassing."
                                                values={{
                                                    privacy: (
                                                        <a
                                                            href={`https://policies.google.com/privacy?hl=${locale}-${locale}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <FormattedMessage defaultMessage="Privacybeleid" />
                                                        </a>
                                                    ),
                                                    terms: (
                                                        <a
                                                            href={` https://policies.google.com/terms?hl=${locale}-${locale}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <FormattedMessage defaultMessage="Servicevoorwaarden" />
                                                        </a>
                                                    ),
                                                }}
                                            />
                                        </Body>
                                    </Box>
                                )}
                            </>
                        )}
                    </FormikForm>
                )}
            </Formik>
        </SmallContentWrapper>
    );
};

export default Form;

const SubmissionMessageContainer = styled.div`
    padding: 2rem;
    background-color: ${theme.colors.primary['10']};
    color: ${theme.colors.primary['100']};
    border-radius: 0.6rem;
    margin: 1rem 0 1rem 0;
`;

const ErrorMessage = ({ error }: { error: Error }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        ref.current?.scrollIntoView({ block: 'center' });
    }, []);

    return (
        <Box mb={5} ref={ref}>
            <Toast title={<FormattedMessage defaultMessage="Formulier kan niet worden verzonden" />} variant="error">
                {error.message}
            </Toast>
        </Box>
    );
};

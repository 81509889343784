import { gql } from '@apollo/client';
import { DateFieldFragment, FieldFragment } from '../../generated/graphql';
import React, { FC } from 'react';
import BirthdateField from '@oberoninternal/travelbase-website/dist/components/designsystem/BirthdateField';
import DatepickerField from '@oberoninternal/travelbase-website/dist/components/DatepickerField';

export const fragment = gql`
    fragment DateField on Field_Date {
        displayType
        defaultDate
        defaultValue
    }
`;

interface Props {
    field: FieldFragment & DateFieldFragment;
}

const DateField: FC<React.PropsWithChildren<Props>> = ({ field }) => {
    const name = field.handle;

    if (!name) {
        return null;
    }

    if (field.displayType === 'dropdowns') {
        return <BirthdateField name={name} />;
    }
    return <DatepickerField align="left" name={name} />;

    return null;
};

export default DateField;

import { gql } from '@apollo/client';
import React, { FC } from 'react';
import {
    EmailTextFieldFragment,
    FieldFragment,
    MultipleTextFieldFragment,
    PhoneTextFieldFragment,
    SingleTextFieldFragment,
} from '../../generated/graphql';
import phoneRegex from '@oberoninternal/travelbase-website/dist/constants/phoneRegex';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { defineMessages, useIntl } from 'react-intl';
import { globalValidationMessages } from '../Form';
import * as Yup from 'yup';

export const fragment = gql`
    fragment TextField on FieldInterface {
        ...SingleTextField
        ...MultipleTextField
        ...EmailTextField
        ...PhoneTextField
    }

    fragment SingleTextField on Field_SingleLineText {
        limit
        maxType
        max
        defaultValue
    }

    fragment MultipleTextField on Field_MultiLineText {
        limit
        maxType
        max
        defaultValue
    }

    fragment EmailTextField on Field_Email {
        defaultValue
    }

    fragment PhoneTextField on Field_Phone {
        defaultValue
    }
`;

interface Props {
    field: FieldFragment &
        (SingleTextFieldFragment | MultipleTextFieldFragment | EmailTextFieldFragment | PhoneTextFieldFragment);
}

const validationMessages = defineMessages({
    characterLimitReached: {
        defaultMessage: 'Dit veld mag maximaal {limit, plural, one {# karakter} other {# karakters}} bevatten.',
    },
    wordLimitReached: {
        defaultMessage: 'Dit veld mag maximaal {limit, plural, one {# woord} other {# woorden}} bevatten.',
    },
    invalidEmail: {
        defaultMessage: 'Dit veld moet een geldig email adres bevatten.',
    },
    invalidPhone: {
        defaultMessage: 'Dit veld moet een geldig telefoonnummer bevatten.',
    },
});

const emailValidator = Yup.string().email();
const phoneValidator = Yup.string().matches(phoneRegex);

const TextField: FC<React.PropsWithChildren<Props>> = ({ field }) => {
    const name = field.handle;
    const { formatMessage } = useIntl();

    if (!name) {
        return null;
    }

    return (
        <TextInputField
            type={field.__typename === 'Field_MultiLineText' ? 'textarea' : undefined}
            placeholder={field.placeholder ?? undefined}
            name={name}
            validate={(value: string) => {
                if (!value && field.required) {
                    return field.errorMessage ? field.errorMessage : formatMessage(globalValidationMessages.required);
                }

                if (value) {
                    if (field.__typename === 'Field_Email' && !emailValidator.isValidSync(value)) {
                        return field.errorMessage ? field.errorMessage : formatMessage(validationMessages.invalidEmail);
                    }
                    if (field.__typename === 'Field_Phone' && !phoneValidator.isValidSync(value)) {
                        return field.errorMessage ? field.errorMessage : formatMessage(validationMessages.invalidPhone);
                    }
                }

                if (
                    (field.__typename === 'Field_SingleLineText' || field.__typename === 'Field_MultiLineText') &&
                    field?.limit &&
                    field?.max
                ) {
                    let total = value.length;
                    if (field.maxType === 'words') {
                        total = value.split(' ').length;
                    }
                    if (total > field.max) {
                        return field.errorMessage
                            ? field.errorMessage
                            : formatMessage(
                                  field.maxType === 'words'
                                      ? validationMessages.wordLimitReached
                                      : validationMessages.characterLimitReached,
                                  {
                                      limit: field.max,
                                  }
                              );
                    }
                }

                return;
            }}
        />
    );
};

export default TextField;

import { FieldFragment, NameFieldFragment } from '../../generated/graphql';
import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { globalValidationMessages } from '../Form';
import { useIntl } from 'react-intl';
import Fieldset from '@oberoninternal/travelbase-website/dist/components/designsystem/Fieldset';
import styled from 'styled-components';
import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import Caret from '../icons/Caret';

export const fragment = gql`
    fragment NameField on Field_Name {
        fields {
            label
            enabled
            required
            handle
            placeholder
        }
    }
`;

interface Props {
    field: FieldFragment & NameFieldFragment;
}

export interface Name {
    firstName: string;
    lastName: string;
    middleName?: string;
    prefix?: string;
}

const StyledCaret = styled(Caret)`
    color: ${({ theme }) => theme.colors.secondary['50']} !important;
    width: 2rem;
    height: 2rem;
`;

const NameField: FC<React.PropsWithChildren<Props>> = ({ field }) => {
    const name = field.handle;
    const { formatMessage } = useIntl();
    if (!name || !field.fields) {
        return null;
    }

    const prefixOptions = [
        {
            label: formatMessage({ defaultMessage: 'Dhr.' }),
            value: 'mr',
        },
        {
            label: formatMessage({ defaultMessage: 'Mevr.' }),
            value: 'mrs',
        },
    ];

    return (
        <FieldsWrapper>
            {field.fields.map((nameField, index) => {
                if (!nameField?.enabled) {
                    return;
                }

                if (nameField?.handle === 'prefix') {
                    return (
                        <FieldsetSmall key={index} label={nameField.label} id={`${name}.${nameField.handle}`}>
                            <StyledSelectInput
                                name={`${name}.${nameField.handle}`}
                                hideChoices
                                options={prefixOptions}
                                filterFromStart
                                id={field.handle}
                                ToggleComponent={StyledCaret ?? undefined}
                                required={nameField.required}
                                placeholder={''}
                                validate={(value: string) => {
                                    if (!value && field.required) {
                                        return field.errorMessage
                                            ? field.errorMessage
                                            : formatMessage(globalValidationMessages.required);
                                    }
                                    return;
                                }}
                            />
                        </FieldsetSmall>
                    );
                }

                if (nameField?.handle === 'middleName') {
                    return (
                        <FieldsetSmall key={index} label={nameField.label} id={`${name}.${nameField.handle}`}>
                            <TextInputField
                                placeholder={nameField.placeholder ?? undefined}
                                name={`${name}.${nameField.handle}`}
                                validate={(value: string) => {
                                    if (!value && field.required) {
                                        return field.errorMessage
                                            ? field.errorMessage
                                            : formatMessage(globalValidationMessages.required);
                                    }
                                    return;
                                }}
                            />
                        </FieldsetSmall>
                    );
                }
                return (
                    <FieldsetBig key={index} label={nameField.label} id={`${name}.${nameField.handle}`}>
                        <TextInputField
                            placeholder={nameField.placeholder ?? undefined}
                            name={`${name}.${nameField.handle}`}
                            validate={(value: string) => {
                                if (!value && field.required) {
                                    return field.errorMessage
                                        ? field.errorMessage
                                        : formatMessage(globalValidationMessages.required);
                                }
                                return;
                            }}
                        />
                    </FieldsetBig>
                );
            })}
        </FieldsWrapper>
    );
};

export default NameField;

const FieldsWrapper = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: flex;
        justify-content: space-between;

        > div {
            margin-right: 1%;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
`;

const FieldsetSmall = styled(Fieldset)`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        width: 15%;
    }
`;

const FieldsetBig = styled(Fieldset)`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        flex-grow: 2;
    }
`;
const StyledSelectInput = styled(SelectInput)`
    > div {
        min-height: 5.2rem;
    }
`;

import React, { FC } from 'react';
import { gql } from '@apollo/client';
import { TipFragment } from '../../generated/graphql';
import styled from 'styled-components';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { Box, Flex } from '@rebass/grid';
import Img from '@oberoninternal/travelbase-website/dist/components/Img';
import HtmlMapper from 'react-html-map';
import tagMap from '../../constants/tagMap';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';

interface Props {
    tip: TipFragment;
}

export const fragment = gql`
    fragment Tip on tip_Entry {
        title
        tipText
        tipName
        tipRole
        tipAvatar {
            height
            width
            thumbUrl: url @transform(width: 56, height: 56, immediately: true)
        }
    }
`;

const TipBlock: FC<React.PropsWithChildren<Props>> = ({ tip }) => {
    const { title, tipAvatar, tipName, tipRole, tipText } = tip;
    return (
        <Box pt={[3, 5, 8]}>
            <TipWrapper>
                <ContentWrapper>
                    <StyledFlex py={[5, 8]} mx={[0, 0, -4]} alignItems="center">
                        <Box width={[1 / 1, 1 / 1, 1 / 1, 3 / 4]} px={[0, 0, 4]}>
                            <StyledTitle variant={'regular'} elementType="h3">
                                {title}
                            </StyledTitle>
                            {tipText && <HtmlMapper html={tipText}>{tagMap}</HtmlMapper>}
                        </Box>
                        <Box width={[1 / 1, 1 / 1, 1 / 1, 1 / 4]} px={[0, 0, 4]}>
                            <Author>
                                {tipAvatar?.[0]?.thumbUrl && (
                                    <ImgWrapper>
                                        <Img
                                            loading="lazy"
                                            layout="fill"
                                            fallback={{ src: tipAvatar?.[0]?.thumbUrl ?? '' }}
                                        />
                                    </ImgWrapper>
                                )}
                                <AuthorInfo>
                                    <span>{tipName}</span>
                                    <Role>{tipRole}</Role>
                                </AuthorInfo>
                            </Author>
                        </Box>
                    </StyledFlex>
                </ContentWrapper>
            </TipWrapper>
        </Box>
    );
};

export default TipBlock;

const TipWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.secondary['20']};
    overflow: hidden;
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 15.4rem;
        height: 27.1rem;
        background-image: url('/static/img/vvvtexel-land.svg');
        position: absolute;
        right: -7rem;
        top: -20.5rem;

        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
            width: 28.8rem;
            height: 50.8rem;
            right: -7rem;
            top: -40rem;
        }
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        p {
            padding-right: 3.2rem;
        }
    }
`;

const Author = styled.div`
    display: flex;
    padding-top: 1.6rem;
    padding-bottom: 0.4rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) and (max-width: ${({ theme }) =>
            theme.mediaQueries.xl}) {
        flex-direction: column;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        flex-direction: row;
    }
`;

const ImgWrapper = styled.div<{ ratio?: number }>`
    position: relative;
    height: 5.6rem;
    width: 5.6rem;
    min-width: 5.6rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1.6rem;

    > div {
        min-width: 5.6rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) and (max-width: ${({ theme }) =>
            theme.mediaQueries.xl}) {
        margin-bottom: 0.8rem;
    }

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: 100%;
    }

    img {
        object-fit: cover;
        object-position: center center;
    }
`;

const Role = styled.span`
    display: block;
    font-weight: 400;
`;

const AuthorInfo = styled.div`
    line-height: 1.5;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 800;
`;
const StyledFlex = styled(Flex)`
    flex-direction: column;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        flex-direction: row;
    }
`;
const StyledTitle = styled(Title)`
    margin-bottom: 0.8rem;
`;

import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { AddressFieldFragment, FieldFragment } from '../../generated/graphql';
import Fieldset from '@oberoninternal/travelbase-website/dist/components/designsystem/Fieldset';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { FormattedMessage, useIntl } from 'react-intl';
import { globalValidationMessages } from '../Form';
import { useCountryOptions } from '@oberoninternal/travelbase-website/dist/hooks/useDetailsFormOptions';
import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import styled from 'styled-components';
import Caret from '../icons/Caret';

export const fragment = gql`
    fragment AddressField on Field_Address {
        fields {
            label
            enabled
            errorMessage
            defaultValue
            required
            placeholder
            handle
        }
    }
`;

interface Props {
    field: FieldFragment & AddressFieldFragment;
}

export interface Address {
    address1: string;
    city: string;
    zip: string;
    country: string;
}

const StyledCaret = styled(Caret)`
    color: ${({ theme }) => theme.colors.secondary['50']} !important;
    width: 2rem;
    height: 2rem;
`;

const AddressField: FC<React.PropsWithChildren<Props>> = ({ field }) => {
    const name = field.handle;
    const { formatMessage } = useIntl();
    const countryOptions = useCountryOptions();

    if (!name || !field.fields) {
        return null;
    }

    const fieldCountry = field.fields.find(countryField => countryField?.handle === 'country');
    const fieldZip = field.fields.find(zipField => zipField?.handle === 'zip');

    return (
        <>
            {field.fields.map(addressField => {
                if (!addressField) return;
                if (
                    (!field && !addressField.enabled && addressField.handle === 'country') ||
                    addressField.handle === 'zip'
                ) {
                    return;
                }
                return (
                    <Fieldset
                        key={addressField.handle}
                        label={addressField.label}
                        id={`${name}.${addressField.handle}`}
                        required={addressField.required ?? false}
                    >
                        <TextInputField
                            placeholder={addressField.placeholder ?? undefined}
                            name={`${name}.${addressField.handle}`}
                            validate={(value: string) => {
                                if (!value && addressField.required) {
                                    return addressField.errorMessage
                                        ? addressField.errorMessage
                                        : formatMessage(globalValidationMessages.required);
                                }
                                return;
                            }}
                        />
                    </Fieldset>
                );
            })}

            <Wrapper>
                {fieldZip?.enabled && (
                    <Fieldset label={fieldZip.label} id={`${name}.zip`}>
                        <TextInputField
                            placeholder={fieldZip.placeholder ?? undefined}
                            name={`${name}.zip`}
                            validate={(value: string) => {
                                if (!value && fieldZip.required) {
                                    return field.errorMessage
                                        ? field.errorMessage
                                        : formatMessage(globalValidationMessages.required);
                                }
                                return;
                            }}
                        />
                    </Fieldset>
                )}
                {fieldCountry?.enabled && (
                    <Fieldset label={fieldCountry.label} id={`${name}.country`}>
                        <StyledSelectInput
                            name={`${name}.country`}
                            hideChoices
                            options={countryOptions}
                            filterFromStart
                            id="country"
                            isSearchable
                            ToggleComponent={StyledCaret ?? undefined}
                            required={fieldCountry.required}
                            placeholder={<FormattedMessage defaultMessage="Kies een optie..." />}
                            validate={(value: string) => {
                                if (!value && fieldCountry.required) {
                                    return field.errorMessage
                                        ? field.errorMessage
                                        : formatMessage(globalValidationMessages.required);
                                }
                                return;
                            }}
                        />
                    </Fieldset>
                )}
            </Wrapper>
        </>
    );
};

export default AddressField;

const Wrapper = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: flex;
        justify-content: space-between;

        > div {
            width: 49%;
        }
    }
`;

const StyledSelectInput = styled(SelectInput)`
    > div {
        min-height: 5.2rem;
    }
`;
